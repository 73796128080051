import { instances } from '../store';
import { inViewport } from './in-viewport';

class CaseVideo {
  constructor(el) {
    this.dom = {};
    this.dom.el = el.closest('section');
    this.dom.player = this.dom.el.querySelector('.js-player');

    this.state = {
      loaded: false
    };
  }

  play() {
    this.dom.player.play();

    instances.time.emitter.off('tick', this.render);
  }

  render = () => {
    if (!instances.scroll) return;

    const isVisible = inViewport(Math.abs(instances.scroll.state.last) + 240, this.dom.el);

    if (isVisible && !this.state.loaded) {
      this.state.loaded = true;
      this.play();
    }
  }

  init() {
    instances.time.emitter.on('tick', this.render);
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default CaseVideo;
