class CookieManager {
  constructor() {
    window.onload = () => {
      if (!this.getCookie('cookies_clicked')) {
        document.querySelector('.cookie').style.display = 'block';
      }
    };

    document.querySelector('.accept-cookies').addEventListener('click', () => this.acceptCookies());
    document.querySelector('.decline-cookies').addEventListener('click', () => this.declineCookies());
  }

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  acceptCookies() {
    this.setCookie('cookies_clicked', 'true', 365);
    document.querySelector('.cookie').style.display = 'none';
  }

  declineCookies() {
    this.setCookie('cookies_clicked', 'true', 365);
    document.querySelector('.cookie').style.display = 'none';

    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
      });
    }

    if (window.fbq) {
      window.fbq('consent', 'revoke');
    }

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'cookies_declined' });
    }

    if (window.google_tag_data) window.google_tag_data = null;
    if (window.google_tag_manager) window.google_tag_manager = null;
  }
}

export default CookieManager;
