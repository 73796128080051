import { desktopVW, mobileVW } from '../../utils/math';

import { constants } from '../../store';

class SocialCarousel {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.buttons = this.dom.el.querySelectorAll('.js-button');
    this.dom.cards = this.dom.el.querySelectorAll('.js-card');

    this.state = {
      currentIndex: 2,
      autoSlideInterval: null,
    };
  }

  handleCards = (e, autoNext = 'next') => {
    const dir = e ? e.currentTarget.dataset.nav : autoNext;

    this.dom.cards.forEach((card) => {
      card.style.transitionDelay = '';
    });

    if (dir === 'prev') {
      if (this.state.currentIndex !== 0) {
        this.state.currentIndex -= 1;
      } else {
        this.state.currentIndex = this.dom.cards.length - 1;

        this.dom.cards.forEach((card, index) => {
          card.style.transitionDelay = `${index * 0.04}s`;
        });
      }
    }

    if (dir === 'next') {
      if (this.state.currentIndex !== this.dom.cards.length - 1) {
        this.state.currentIndex += 1;
      } else {
        this.state.currentIndex = 0;

        this.dom.cards.forEach((card, index) => {
          const reverseIndex = this.dom.cards.length - index - 1;
          card.style.transitionDelay = `${reverseIndex * 0.04}s`;
        });
      }
    }

    this.dom.cards.forEach((card, index) => {
      card.classList.remove('active');

      const video = card.querySelector('video');

      if (index === this.state.currentIndex) {
        card.classList.add('active');
        card.style.translate = '';

        if (!video) {
          this.resetAutoSlide(10000);
        } else {
          video.addEventListener('loadedmetadata', () => {
            card.style.setProperty('--duration', `${video.duration}s`);
          });

          video.currentTime = 0;
          video.play();
          this.resetAutoSlide(video.duration * 1000);
        }

        return;
      }

      let x;

      if (!constants.isDevice) {
        if (index < this.state.currentIndex) {
          x = 150 - (this.state.currentIndex - 1 - index) * 240;
        }

        if (index > this.state.currentIndex) {
          x = 1080 + (index - this.state.currentIndex - 1) * 240;
        }

        if (video) {
          video.pause();
        }

        card.style.translate = `${desktopVW(x)}`;
      } else {
        if (index < this.state.currentIndex) {
          x = -98 - (this.state.currentIndex - 1 - index) * 136;
        }

        if (index > this.state.currentIndex) {
          x = 330 + (index - this.state.currentIndex - 1) * 136;
        }

        if (video) {
          video.pause();
        }

        card.style.translate = `${mobileVW(x)}`;
      }
    });
  }

  resetAutoSlide(duration = 2000) {
    clearInterval(this.state.autoSlideInterval);

    this.state.autoSlideInterval = setInterval(() => this.handleCards(null, 'next'), duration + 600);
  }

  addListeners() {
    [...this.dom.buttons].forEach((button) => {
      button.addEventListener('click', this.handleCards);
    });
  }

  firstCard() {
    setTimeout(() => {
      this.dom.cards[2].classList.add('active');
    }, 1);

    const video = this.dom.cards[2].querySelector('video');

    if (video) {
      video.addEventListener('loadedmetadata', () => {
        this.resetAutoSlide(video.duration * 1000);
      });
    }

    this.resetAutoSlide(10000);
  }

  checkVideoDuration() {
    this.dom.cards.forEach((card) => {
      const video = card.querySelector('video');

      if (video) {
        video.addEventListener('loadedmetadata', () => {
          card.style.setProperty('--duration', `${video.duration}s`);
        });
      } else {
        card.style.setProperty('--duration', '10s');
      }
    });
  }

  init() {
    this.addListeners();
    this.firstCard();
    this.checkVideoDuration();
  }

  destroy() {
    clearInterval(this.state.autoSlideInterval);

    [...this.dom.buttons].forEach((button) => {
      button.removeEventListener('click', this.handleCards);
    });
  }
}

export default SocialCarousel;
