import gsap from 'gsap/gsap-core';
import { constants, instances } from '../../store';
import { inViewport, /* intersectMinMax, intersectTopMinMax */ } from '../../utils/in-viewport';

class CaseHeader {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-case-header');
    this.dom.imgWrap = (!constants.isDevice) ? this.dom.el.querySelector('.js-image') : this.dom.el.querySelector('.js-mobile-image');
    this.dom.img = this.dom.el.querySelector('.js-img');
    this.dom.player = this.dom.el.querySelector('.js-player');

    this.dom.nav = document.querySelector('.js-nav');

    this.state = {
      top: this.dom.imgWrap.getBoundingClientRect().top,
      themeChanged: false,
      active: false,
      paused: false
    };
  }

  setTheme() {
    const { theme } = this.dom.el.dataset;

    if (!theme) return;

    this.dom.nav.classList.toggle('is-light');

    instances.time.emitter.on('tick', this.renderNav);
  }

  renderNav = () => {
    if (Math.abs(instances.scroll.state.last) >= this.state.top && !this.state.themeChanged) {
      this.state.themeChanged = true;
      this.dom.nav.classList.toggle('is-light');
    } else if (Math.abs(instances.scroll.state.last) <= this.state.top && this.state.themeChanged && instances.scroll.state.direction === 'up') {
      this.state.themeChanged = false;
      this.dom.nav.classList.toggle('is-light');
    }
  }

  render = () => {
    const inView = inViewport(Math.abs(instances.scroll.state.last), this.dom.imgWrap);

    if (inView) {
      if (Math.abs(instances.scroll.state.last) >= this.state.top) {
        gsap.set(this.dom.imgWrap, { y: Math.abs(instances.scroll.state.last + this.state.top) });
      }

      if (this.dom.player && this.state.paused) {
        this.state.paused = false;
        this.dom.player.play();
      }

      if (!this.dom.image) return;

      const { top } = this.dom.el.getBoundingClientRect();
      let percentage = (Math.abs(top) / this.dom.el.offsetHeight) * 100;
      const scale = ((percentage - 0) / (100 - 0)) * (1 - 0.9) + 1;
      gsap.set(this.dom.img, { scale: scale });
    } else if (!inView && this.dom.player && !this.state.paused) {
      this.state.paused = true;
      this.dom.player.pause();
    }
  }

  init() {
    this.setTheme();

    if (constants.isDevice) return;

    const interval = setInterval(() => {
      if (constants.pageEntranceFinished) {
        clearInterval(interval);

        instances.time.emitter.on('tick', this.render);
      }
    });
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
    instances.time.emitter.off('tick', this.renderNav);
  }
}

export default CaseHeader;
