import Highway from '@dogstudio/highway';
import { onLeave, onEnter, onEnterCompleted } from '../renderers/global';

// renderers
import PageRenderer from '../renderers/page';

// transitions
import defaultTransition from '../transitions/default';

// setup highway core
export const H = new Highway.Core({
  renderers: {
    page: PageRenderer
  },
  transitions: {
    default: defaultTransition,
    contextual: {
      default: defaultTransition,
    }
  }
});

// global highway events
H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
  onLeave({ from, trigger, location });
});

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  onEnter({ to, trigger, location });
});

H.on('NAVIGATE_END', ({
  from, to, trigger, location
}) => {
  onEnterCompleted({
    from, to, trigger, location
  });
});
