import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';

import { constants } from '../store';

gsap.registerPlugin(SplitText, CustomEase);

CustomEase.create('in-out-soft', 'M0,0 C0.6,0 0.4,1 1,1');
CustomEase.create('in-out-fast', 'M0,0 C0.19,0 0.27,1 1,1 ');
CustomEase.create('short-in-out-long', 'M0,0 C0.43,0 0.2,1 1,1 ');
CustomEase.create('short-in-out-long2', 'M0,0 C0.53115,0 0.20586,1 1,1 ');
CustomEase.create('text-easing', 'M0,0 C0.44652,0 0.0509,1 1,1 ');
CustomEase.create('out-long', 'M46.668,0C77.4674,0,61.3344,0,193.332,0 ');
CustomEase.create('image', 'M0,0 C0.21,0 0.1,1 1,1');

const tl = gsap.timeline({
  paused: true,
  onComplete: () => {
    document.body.classList.remove('is-transitioning');
  }
});

const setTextHeaderAnim = (dom) => {
  dom.subheading = dom.header.querySelectorAll('.js-subheading');

  tl
    .set(dom.headingLines, { y: 86, scale: 0.6 }, 'start')
    .set(dom.nextElement, { y: 220, autoAlpha: 0 }, 'start')

  // header
    .to(dom.headingLines, { y: 0, scale: 1, autoAlpha: 1, duration: 1, ease: 'text-easing',
      stagger: 0.14, transformOrigin: 'center center' }, 'start+=0.11')
    .to(dom.nextElement, { y: 0, autoAlpha: 1, duration: 1.46, ease: 'text-easing', onComplete: () => { constants.pageEntranceFinished = true; } }, 'start+=0.28');

  if (dom.subheading.length > 0) {
    tl
      .set(dom.subheading, { y: 120 }, 'start')
      .to(dom.subheading, { y: 0, duration: 1.46, ease: 'text-easing', stagger: 0.13 }, 'start')
      .to(dom.subheading, { autoAlpha: 1, duration: 0.97, ease: 'text-easing', stagger: 0.13 }, 'start+=0.28');
  }

  tl.play();
};

const setTextImageHeaderAnim = (dom) => {
  constants.pageEntranceFinished = true;

  dom.bigImage = dom.header.querySelector('.js-img-big');
  dom.smallImage = dom.header.querySelectorAll('.js-img-small');

  tl
    .set(dom.headingLines, { y: 113, scale: 0.7 }, 'start')
    .set(dom.bigImage, { y: 456, rotate: 3 }, 'start')
    .set(dom.smallImage, { y: 117 }, 'start')

    // header
    .to(dom.headingLines, { y: 0, scale: 1, autoAlpha: 1, duration: 1, ease: 'text-easing',
      stagger: 0.14, transformOrigin: 'center center' }, 'start+=0.11')
    .to(dom.bigImage, { y: 0, rotate: 0, autoAlpha: 1, duration: 1.2, ease: 'image' }, 'start+=0.24')
    .to(dom.smallImage, { y: 0, autoAlpha: 1, duration: 1.2, ease: 'image', stagger: 0.05 }, 'start+=0.24');

  tl.play();
};

const setContactHeaderAnim = (dom) => {
  constants.pageEntranceFinished = true;

  dom.image = dom.header.querySelector('.js-img');
  dom.cols = dom.header.querySelectorAll('.js-col');

  tl
    .set(dom.headingLines, { y: 113, scale: 0.7 }, 'start')
    .set(dom.cols, { y: 113 }, 'start')
    .set(dom.image, { y: 456, rotate: 3 }, 'start')

    // header
    .to(dom.headingLines, { y: 0, scale: 1, autoAlpha: 1, duration: 1, ease: 'text-easing',
      stagger: 0.14, transformOrigin: 'center center' }, 'start+=0.11')
    .to(dom.cols, { y: 0, autoAlpha: 1, duration: 1, ease: 'text-easing', stagger: 0.14 }, 'start+=0.24')
    .to(dom.image, { y: 0, rotate: 0, autoAlpha: 1, duration: 1.2, ease: 'image' }, 'start+=0.24');

  tl.play();
};

const setHomeHeaderAnim = (dom) => {
  // Start creating circle
  const w3 = 'http://www.w3.org/2000/svg';

  dom.lastLine = dom.header.querySelectorAll('div > .header__heading-line');
  dom.lastLine = dom.lastLine[dom.lastLine.length - 1];

  dom.circle = document.createElementNS(w3, 'svg');
  dom.circle.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', w3);

  dom.circle.setAttributeNS(null, 'viewBox', '0 0 14 14');

  const circle = document.createElementNS(w3, 'circle');
  circle.setAttributeNS(null, 'cx', 7);
  circle.setAttributeNS(null, 'cy', 7);
  circle.setAttributeNS(null, 'r', 6);
  dom.circle.appendChild(circle);

  dom.circle.classList.add('header__heading-circle', 'js-circle');
  dom.lastLine.appendChild(dom.circle);
  // End creating circle

  dom.subheading = dom.header.querySelectorAll('.js-subheading');
  dom.circle = dom.header.querySelector('.js-circle');

  if (dom.subheading.length > 0) tl.set(dom.subheading, { y: 110, autoAlpha: 0 }, 'start');

  tl
    .set(dom.headingLines, { y: 120, scale: 0.6 }, 'start')
    .set(dom.nextElement, { y: 110, autoAlpha: 0 }, 'start')
    .set(dom.circle, { autoAlpha: 0, y: 116, strokeDasharray: 180, strokeDashoffset: 180 }, 'start')

  // header
    .to(dom.headingLines, { y: 0, scale: 1, autoAlpha: 1, duration: 0.93, ease: 'text-easing',
      stagger: 0.13 }, 'start+=0.11')
    .to(dom.circle, { autoAlpha: 1, y: 0, strokeDasharray: 180, strokeDashoffset: 140, ease: 'text-easing', duration: 1.46 }, 'start')
    .to(dom.nextElement, { y: 0, autoAlpha: 1, duration: 1.20, ease: 'text-easing', onComplete: () => { constants.pageEntranceFinished = true; } }, 'start+=0.28');

  if (dom.subheading.length > 0) tl.to(dom.subheading, { y: 0, autoAlpha: 1, duration: 1.20, ease: 'text-easing' }, 'start+=0.28');

  tl.play();
};

const setCaseHeaderAnim = (dom) => {
  dom.subheading = dom.header.querySelectorAll('.js-subheading');
  dom.image = (!constants.isDevice) ? dom.header.querySelector('.js-image') : dom.header.querySelector('.js-mobile-image');

  constants.pageEntranceFinished = true;

  tl
    .set([dom.headingLines, dom.subheading], { y: dom.headingLines[0].offsetHeight }, 'start')
    .set(dom.nextElement, { autoAlpha: 1 }, 'start')
    .set(dom.image, { y: 220, autoAlpha: 0 }, 'start')

    .to([dom.headingLines, dom.subheading], { y: 0, autoAlpha: 1, duration: 1, ease: 'text-easing', stagger: 0.14 }, 'start+=0.11')
    .to(dom.image, { y: 0, autoAlpha: 1, duration: 1.46, ease: 'text-easing' }, 'start+=0.11');

  tl.play();
};

const setDefaultAnim = (dom) => {
  dom.error = document.querySelector('.js-error');

  tl
    .set(dom.error, { y: 110, autoAlpha: 0 }, 'start')
    .to(dom.error, { y: 0, autoAlpha: 1, duration: 1.20, ease: 'text-easing', onComplete: () => { constants.pageEntranceFinished = true; } }, 'start+=0.28');

  tl.play();
};

export const pageEntrance = () => {
  const dom = {};

  dom.nav = document.body.querySelector('.js-nav');
  dom.logo = dom.nav.querySelector('.js-logo');
  dom.navItems = dom.nav.querySelectorAll('.js-item');

  if (constants.headerLayout) {
    dom.header = document.body.querySelector('.js-header');
    dom.heading = dom.header.querySelector('.js-heading');
    dom.headingLines = new SplitText(dom.heading, { type: 'lines', linesClass: 'header__heading-wrap' }).lines;
    dom.headingLines = new SplitText(dom.headingLines, { type: 'lines', linesClass: 'header__heading-line' }).lines;

    dom.nextElement = dom.header.parentNode.querySelector('section');
  }

  tl
    .addLabel('start')

  // nav
    .set(dom.logo, { x: -dom.logo.offsetWidth, autoAlpha: 0 })
    .set(dom.nav, { autoAlpha: 1 })
    .set(dom.navItems, { y: -20, scale: 0.7, autoAlpha: 0 });

  if (constants.headerLayout) {
    // header
    tl
      .set(dom.headingLines, { autoAlpha: 0 })
      .set(dom.heading, { autoAlpha: 1 });
  }

  // nav
  tl
    .to(dom.logo, { x: 0, autoAlpha: 1, duration: 0.57, ease: 'short-in-out-long2' }, 'start')
    .to(dom.navItems, { y: 0, scale: 1, autoAlpha: 1, duration: 0.72, ease: 'short-in-out-long2', stagger: 0.07 }, 'start+=0.28');

  switch (constants.headerLayout) {
    case 'text':
      setTextHeaderAnim(dom);
      break;

    case 'text-image':
      setTextImageHeaderAnim(dom);
      break;

    case 'contact':
      setContactHeaderAnim(dom);
      break;

    case 'home':
      setHomeHeaderAnim(dom);
      break;

    case 'case':
      setCaseHeaderAnim(dom);
      break;

    default:
      setDefaultAnim(dom);
      break;
  }
};
