import { gsap } from 'gsap';

import { lerp } from '../../utils/math';
import { constants, instances } from '../../store';

import { inViewport } from '../../utils/in-viewport';

class List {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-list');
    this.dom.list = document.querySelector('.js-award-list');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');

    this.dom.app = document.querySelector('.js-app');
  }

  createImage() {
    this.dom.image = document.createElement('img');
    this.dom.image.classList.add('list__img', 'js-list-image');
    this.dom.app.appendChild(this.dom.image);

    this.state = {
      width: this.dom.image.offsetWidth,
      height: this.dom.image.offsetHeight,
      show: false,
    };

    this.mouse = {
      x: -this.state.width,
      y: -this.state.height
    };

    this.last = {
      x: this.mouse.x,
      y: this.mouse.y
    };
  }

  render = () => {
    // Show list items
    if (!instances.scroll) return;

    const isVisible = inViewport(Math.abs(instances.scroll.state.last), this.dom.el);

    if (isVisible) {
      [...this.dom.items].forEach((item) => {
        if (item.getBoundingClientRect().top - window.innerHeight < -50) {
          gsap.to(item, { autoAlpha: 1, y: 0, scale: 1 });
        }
      });
    }

    if (!this.dom.list || constants.isDevice) return;

    this.last = {
      x: lerp(this.last.x, this.mouse.x, 0.15),
      y: lerp(this.last.y, this.mouse.y, 0.15)
    };

    if (this.state.show) {
      gsap.set(this.dom.image, {
        x: this.last.x,
        y: this.last.y
      });
    }
  }

  show() {
    if (this.state.show) return;

    gsap.killTweensOf(this.dom.image);

    gsap.to(this.dom.image, {
      duration: 0.2,
      autoAlpha: 1,
    });

    this.state.show = true;
  }

  hide() {
    if (!this.state.show) return;

    gsap.to(this.dom.image, {
      duration: 0.2,
      autoAlpha: 0,
      onComplete: () => {
        this.dom.image.src = '';
      }
    });

    setTimeout(() => {
      this.state.show = false;
    }, 200);
  }

  changeImage = (e) => {
    const { image } = e.target.dataset;
    this.dom.image.src = image;
  }

  handleMouseenter = () => {
    this.show();
  }

  handleMouseleave = () => {
    this.hide();
  }

  handleMousemove = (e) => {
    this.mouse = {
      x: e.clientX,
      y: e.clientY
    };
  }

  addListeners() {
    [...this.dom.items].forEach((item) => {
      item.addEventListener('mouseenter', this.changeImage);
    });

    this.dom.list.addEventListener('mouseenter', this.handleMouseenter);
    this.dom.list.addEventListener('mouseleave', this.handleMouseleave);

    window.addEventListener('mousemove', this.handleMousemove);
  }

  removeListeners() {
    [...this.dom.items].forEach((item) => {
      item.removeEventListener('mouseenter', this.changeImage);
    });

    this.dom.list.removeEventListener('mouseenter', this.handleMouseenter);
    this.dom.list.removeEventListener('mouseleave', this.handleMouseleave);

    window.removeEventListener('mousemove', this.handleMousemove);
  }

  init() {
    instances.time.emitter.on('tick', this.render);

    if (!constants.isDevice) {
      if (!this.dom.list) return;
      this.createImage();
      this.addListeners();
    }
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);

    if (!constants.isDevice) {
      if (!this.dom.list) return;
      gsap.to(this.dom.image, { autoAlpha: 0, duration: 0.2, onComplete: () => this.dom.image.remove() });
      this.removeListeners();
    }
  }
}

export default List;
