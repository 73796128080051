import { gsap } from 'gsap';

import { instances } from '../../store';
import { inViewport } from '../../utils/in-viewport';

class Video {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.player = this.dom.el.querySelector('.js-player');
    this.dom.button = this.dom.el.querySelector('.js-button');
  }

  play() {
    instances.time.emitter.on('tick', this.render);

    this.dom.player.play();
    gsap.to(this.dom.poster, { duration: 0.6, autoAlpha: 0, delay: 0.1 });
  }

  pause() {
    instances.time.emitter.off('tick', this.render);

    this.dom.player.pause();
    gsap.to(this.dom.poster, { duration: 0.6, autoAlpha: 1 });
  }

  render = () => {
    if (!instances.scroll) return;

    const isVisible = inViewport(Math.abs(instances.scroll.state.last), this.dom.el);

    // pause video if playing and out of the viewport
    if (!isVisible && !this.dom.player.paused) this.handleClick();
  }

  handleClick = () => {
    if (!this.dom.poster) this.dom.poster = this.dom.el.querySelector('.img');

    const { paused } = this.dom.player;

    if (paused) this.play();
    else this.pause();

    this.dom.el.classList.toggle('is-playing');
  }

  addListener() {
    this.dom.player.addEventListener('click', this.handleClick);
  }

  removeListener() {
    this.dom.player.removeEventListener('click', this.handleClick);
  }

  init() {
    this.addListener();
  }

  destroy() {
    this.removeListener();
  }
}

export default Video;
