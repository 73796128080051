import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { inViewport } from '../../utils/in-viewport';
import { constants, instances } from '../../store';

gsap.registerPlugin(CustomEase);

CustomEase.create('custom-ease', 'M0,0 C0.33333,0 0.00502,1 1,1');

class Logos {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.heading = this.dom.el.querySelector('.js-heading');
    this.dom.logos = this.dom.el.querySelectorAll('.js-logo');

    this.state = {
      animated: false
    };
  }

  setCache() {
    let perChunk = (!constants.isDevice) ? 4 : 2;

    this.logos = [...this.dom.logos].reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  render = () => {
    if (!instances.scroll) return;

    const isVisible = inViewport(Math.abs(instances.scroll.state.last), this.dom.el);

    if (isVisible && !this.state.animated) {
      this.state.animated = true;

      this.animation();
    }

    if (isVisible) {
      this.logos.forEach((logos) => {
        if (logos[0].getBoundingClientRect().top - window.innerHeight < -50) {
          gsap.to(logos, { autoAlpha: 1, y: 0, duration: 0.76, stagger: 0.08 });
        }
      });
    }

    if (this.logos[this.logos.length - 1][this.logos[this.logos.length - 1].length - 1].style.opacity === 1) {
      instances.time.emitter.off('tick', this.render);
    }
  }

  animation() {
    const tl = gsap.timeline({ paused: true });

    tl
      .addLabel('start')
      .set(this.dom.heading, { y: 30 })
      .to(this.dom.heading, { y: 0, autoAlpha: 1, duration: 0.72, ease: 'custom-ease' }, 'start');

    tl.play();
  }

  init() {
    instances.time.emitter.on('tick', this.render);
    this.setCache();
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default Logos;
