import gsap from 'gsap';

import { lerp } from '../../utils/math';

import { instances } from '../../store';
class Cursor {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-cursor');
    this.dom.inner = this.dom.el.querySelector('.js-inner');
    this.dom.icon = this.dom.el.querySelector('.js-icon');
    this.dom.triggers = document.querySelectorAll('[data-cursor-trigger]');

    this.state = {
      size: this.dom.inner.offsetWidth,
      show: false,
    };

    this.mouse = {
      x: -this.state.size,
      y: -this.state.size
    };

    this.last = {
      x: this.mouse.x,
      y: this.mouse.y
    };
  }

  render = () => {
    this.last = {
      x: lerp(this.last.x, this.mouse.x, 0.15),
      y: lerp(this.last.y, this.mouse.y, 0.15)
    };

    const diffX = Math.abs(this.mouse.x - this.last.x);
    const diffY = Math.abs(this.mouse.y - this.last.y);
    const diffMax = Math.abs(Math.max(diffX, diffY));
    const scale = diffMax * 0.007 + 1;

    gsap.set(this.dom.inner, {
      x: this.last.x - this.state.size / 2,
      y: this.last.y - this.state.size / 2,
    });

    gsap.to(this.dom.inner, {
      duration: 0.5,
      scale
    });
  }

  setIcon(icon) {
    const w3 = 'http://www.w3.org/2000/svg';

    this.icon = document.createElementNS(w3, 'svg');
    this.icon.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', w3);

    switch (icon) {
      case 'play': {
        this.icon.setAttributeNS(null, 'viewBox', '0 0 18.538 23.246');

        const path = document.createElementNS(w3, 'path');
        path.setAttributeNS(null, 'd', 'M.69.69l17.348 11.024L.69 22.742V9.323');
        this.icon.appendChild(path);

        this.icon.classList.add('cursor__play');
        this.dom.icon.appendChild(this.icon);
        break;
      }

      case 'pause': {
        this.icon.setAttributeNS(null, 'viewBox', '0 0 11.771 15.33');

        const path1 = document.createElementNS(w3, 'path');
        path1.setAttributeNS(null, 'd', 'M1 1v13.33');
        this.icon.appendChild(path1);

        const path2 = document.createElementNS(w3, 'path');
        path2.setAttributeNS(null, 'd', 'M10.772 1v13.33');
        this.icon.appendChild(path2);

        this.icon.classList.add('cursor__pause');
        this.dom.icon.appendChild(this.icon);
        break;
      }

      case 'grid': {
        this.icon.setAttributeNS(null, 'viewBox', '0 0 26 26');

        const path1 = document.createElementNS(w3, 'path');
        path1.setAttributeNS(null, 'd', 'M13 1v24');
        this.icon.appendChild(path1);

        const path2 = document.createElementNS(w3, 'path');
        path2.setAttributeNS(null, 'd', 'M25 13H1');
        this.icon.appendChild(path2);

        this.icon.classList.add('cursor__grid');
        this.dom.icon.appendChild(this.icon);
        break;
      }

      default:
        break;
    }
  }

  show(cursor) {
    if (this.state.show) return;

    this.setIcon(cursor);

    gsap.killTweensOf(this.dom.el);

    gsap.set(this.dom.el, {
      duration: 0.2,
      autoAlpha: 1,
    });

    this.state.show = true;
  }

  removeIcon() {
    this.dom.icon.innerHTML = '';
  }

  hide() {
    if (!this.state.show) return;

    this.removeIcon();

    gsap.to(this.dom.el, {
      duration: 0.2,
      autoAlpha: 0,
    });

    this.state.show = false;
  }

  handleMouseenter = (e) => {
    const { cursor } = e.target.dataset;

    this.show(cursor);
  }

  handleMouseleave = () => {
    this.hide();
  }

  handleMouseclick = (e) => {
    const { cursor } = e.target.dataset;

    if (!['play', 'pause'].includes(cursor)) return;

    let cursorAttr;

    switch (cursor) {
      case 'play': cursorAttr = 'pause'; break;
      case 'pause': cursorAttr = 'play'; break;
      default: break;
    }

    e.target.setAttribute('data-cursor', cursorAttr);
    const newCursor = e.target.dataset.cursor;

    gsap.to(this.dom.icon, { autoAlpha: 0, scale: 0, duration: 0.2, onComplete: () => {
      this.dom.icon.querySelectorAll('*').forEach(icon => icon.remove());
      this.setIcon(newCursor);
    } });
    gsap.to(this.dom.icon, { autoAlpha: 1, scale: 1, duration: 0.2, delay: 0.2 });
  }

  handleMousemove = (e) => {
    this.mouse = {
      x: e.clientX,
      y: e.clientY
    };
  }

  addListeners() {
    [...this.dom.triggers].forEach((trigger) => {
      trigger.addEventListener('mouseenter', this.handleMouseenter);
      trigger.addEventListener('mouseleave', this.handleMouseleave);
      trigger.addEventListener('click', this.handleMouseclick);
    });
    window.addEventListener('mousemove', this.handleMousemove);
  }

  removeListeners() {
    [...this.dom.triggers].forEach((trigger) => {
      trigger.removeEventListener('mouseenter', this.handleMouseenter);
      trigger.removeEventListener('mouseleave', this.handleMouseleave);
      trigger.removeEventListener('click', this.handleMouseclick);
    });
    window.removeEventListener('mousemove', this.handleMousemove);
  }

  init() {
    this.addListeners();
    instances.time.emitter.on('tick', this.render);
  }

  reset() {
    this.hide();
    this.removeListeners();
    instances.time.emitter.off('tick', this.render);
  }
}

export default Cursor;
