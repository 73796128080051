class FAQ {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-faq');
    this.dom.items = this.dom.el.querySelectorAll('.js-faq-item');

    this.state = {
      activeQuestions: [],
    };
  }

  toggleQuestion = (index) => {
    const item = this.dom.items[index];
    const answer = item.querySelector('.js-faq-answer');
    const icon = item.querySelector('.js-faq-icon');
    const isActive = this.state.activeQuestions.includes(index);

    if (isActive) {
      this.state.activeQuestions.splice(
        this.state.activeQuestions.indexOf(index),
        1
      );
      answer.classList.remove('is-active');
      icon.classList.remove('is-active');
    } else {
      this.state.activeQuestions.push(index);
      answer.classList.add('is-active');
      icon.classList.add('is-active');
    }
  };

  setInitialState() {
    this.dom.items.forEach((item) => {
      const answer = item.querySelector('.js-faq-answer');
      const icon = item.querySelector('.js-faq-icon');
      answer.classList.remove('is-active');
      icon.classList.remove('is-active');
    });
  }

  addListeners() {
    this.dom.items.forEach((item, index) => {
      const question = item.querySelector('.js-faq-question');
      question.addEventListener('click', () => this.toggleQuestion(index));
    });
  }

  removeListeners() {
    this.dom.items.forEach((item, index) => {
      const question = item.querySelector('.js-faq-question');
      question.removeEventListener('click', () => this.toggleQuestion(index));
    });
  }

  init() {
    this.setInitialState();
    this.addListeners();
  }

  destroy() {
    this.removeListeners();
  }
}

export default FAQ;
