import { gsap } from 'gsap';
import CustomEase from 'gsap/CustomEase';

import { pageEntrance } from '../../utils/page-entrance-anims';
import { pxVw } from '../../utils/math';
import { constants } from '../../store';

CustomEase.create('text-easing', 'M0,0 C0.6,0 0.4,1 1,1');
CustomEase.create('image-easing', 'M0,0 C0.8,0 0.2,1 1,1');
CustomEase.create('fast-in-long-out', 'M0,0 C0.43,0 0.08611,1 1,1');
CustomEase.create('slow-in-out', 'M0,0 C0.16667,0 0.83333,1 1,1');
CustomEase.create('long-scale-easing', 'M0,0 C0.46,0 0.7,1 1,1');

gsap.registerPlugin(CustomEase);

class Preloader {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-preloader');
    this.dom.wrap = this.dom.el.querySelector('.js-preloader-wrap');
    this.dom.lion = this.dom.el.querySelector('.js-preloader-lion');
    this.dom.titles = this.dom.el.querySelector('.js-preloader-titles');
    this.dom.list = this.dom.el.querySelector('.js-preloader-list');
    this.dom.items = this.dom.el.querySelectorAll('.js-preloader-item');
    this.dom.slideshow = this.dom.el.querySelector('.js-slideshow');
    this.dom.imgWrap = this.dom.el.querySelector('.js-img-wrap');
    this.dom.imgs = this.dom.el.querySelectorAll('.js-img');

    this.state = {
      lionWidth: this.dom.lion.getBoundingClientRect().width / 2,
      animationCompleted: false,
      next: false
    };
  }

  animate() {
    const tl = gsap.timeline({ paused: true, onComplete: () => {
      this.state.animationCompleted = true;
      constants.showPreloader = false;
    } });
    const tlTitles = gsap.timeline({ paused: true });
    const tlImages = gsap.timeline({ paused: true });

    tl
      .addLabel('start')
      .set(this.dom.slideshow, { y: 284 })
      .set(this.dom.imgWrap, { autoAlpha: 1, scale: 2 })
      .set(this.dom.imgs[0], { autoAlpha: 1 })
      .set(this.dom.titles, { x: this.dom.items[0].offsetWidth / 2 })
      .set(this.dom.items[0], { x: -this.dom.items[0].offsetWidth - this.state.lionWidth })
      .set(this.dom.list, { autoAlpha: 1 })

      .to(this.dom.lion, { scale: 1, duration: 2.06, ease: 'fast-in-long-out' }, 'start+=0.46')
      .to(this.dom.lion, { x: -this.state.lionWidth * 1.5, duration: 0.81, ease: 'fast-in-long-out' }, 'start+=1.72')

      .to(this.dom.slideshow, { y: 0, autoAlpha: 0.9, scale: 1, duration: 1.45, ease: 'image-easing' }, 'start+=1')
      .to(this.dom.imgWrap, { scale: 1, duration: 1.45, ease: 'image-easing' }, 'start+=1')

      .to(this.dom.titles, { x: this.state.lionWidth, duration: 0.81, ease: 'fast-in-long-out' }, 'start+=1.72')
      .to(this.dom.items[0], { x: 0, duration: 0.81, ease: 'fast-in-long-out', onComplete: () => {
        tlImages.play();
        tlTitles.play();
      } }, 'start+=1.72')
      .to(this.dom.imgs[0], { rotate: -1.5, scale: 1.02, duration: 2, ease: 'fast-in-long-out' }, 'start+=1.7');

    let y = (!constants.isDevice) ? pxVw(1440, 41) : pxVw(360, 25);
    let dur = 0.4;
    let totalDur = 0;

    let durImg = 0.4;

    for (let i = 0; i < this.dom.items.length; i += 1) {
      if (i > this.dom.items.length - 2) {
        tlTitles.to(this.dom.list, { duration: dur, ease: 'text-easing' });
      } else {
        tlTitles.to(this.dom.list, { y: -y, duration: dur, ease: 'text-easing' });
      }

      y += (!constants.isDevice) ? pxVw(1440, 41) : pxVw(360, 25);

      if (i < Math.round(this.dom.items.length) / 2) dur -= 0.035;
      else dur += 0.035;

      totalDur += dur;
    }

    for (let i = 0; i < this.dom.imgs.length; i += 1) {
      tlImages
        .set(this.dom.imgs[i], { autoAlpha: 1 })
        .to(this.dom.imgs[i], { rotate: -1.5, scale: 1.02, duration: durImg, ease: 'slow-in-out' });

      if (i < Math.round(this.dom.imgs.length) / 2) durImg -= 0.035;
      else durImg += 0.035;
    }

    totalDur -= 0.1;

    tl
      .to(this.dom.slideshow, { scale: 0.5, duration: 1.28, ease: 'fast-in-long-out' }, `start+=${totalDur + 2.3}`)
      .to(this.dom.imgWrap, { scale: 1.5, duration: 1.28, ease: 'fast-in-long-out' }, `start+=${totalDur + 2.3}`)
      .to(this.dom.el, { y: -(this.dom.el.offsetHeight + 30), duration: 1.28, ease: 'fast-in-long-out', }, `start+=${totalDur + 2.3}`)
      .to(this.dom.wrap, { y: this.dom.el.offsetHeight / 2.5, autoAlpha: 0, duration: 1.28, ease: 'fast-in-long-out', }, `start+=${totalDur + 2.3}`);

    tl.play();

    setTimeout(() => pageEntrance(document.body), tl.duration() * 1000 - 1000);

    const intervalDone = setInterval(() => {
      if (this.state.animationCompleted) {
        clearInterval(intervalDone);

        this.destroy();
      }
    });
  }

  init() {
    this.animate();
  }

  destroy() {
    this.dom.el.remove();
  }
}

export default Preloader;
