import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants, instances } from '../store';
import { pageEntrance } from '../utils/page-entrance-anims';

gsap.registerPlugin(CustomEase);

CustomEase.create('radius', 'M0,0 C0.6,0 0.4,1 1,1');
CustomEase.create('transform', 'M0,0 C0.43,0 0.2,1 1,1 ');

const transitionEl = document.querySelector('.js-transition');
const transitionText = transitionEl.querySelector('.js-transition-text');

const nav = document.querySelector('.js-nav');
class DefaultTransition extends Highway.Transition {
  out({ trigger, from, done }) {
    instances.scroll.disable();

    if (instances.menu.state.open) {
      gsap.set(nav, { autoAlpha: 1 });

      done();
    } else {
      const tl = gsap.timeline({ paused: true });

      if (trigger !== 'popstate' && trigger.dataset.background) {
        transitionText.innerHTML = '';
        transitionEl.style.backgroundColor = trigger.dataset.background;
      } else if (trigger === 'popstate') {
        transitionText.innerHTML = 'Loading...';
      } else if (trigger.dataset.title) {
        transitionText.innerHTML = trigger.dataset.title;
      } else {
        transitionText.innerHTML = 'Loading...';
      }

      const { top } = from.getBoundingClientRect();
      const height = (top >= 0) ? window.innerHeight / 2 : Math.abs(top);

      tl
        .addLabel('start')
        .set(transitionEl, { autoAlpha: 1, yPercent: 100 })
        .set(transitionText, { yPercent: -35, rotate: 20, scale: 1.1 })

        .to(transitionEl, { borderRadius: 0, duration: 0.9, ease: 'radius' }, 'start')
        .to(transitionEl, { yPercent: 0, duration: 1.28, ease: 'transform' }, 'start')
        .to(transitionText, { yPercent: 0, rotate: 0, scale: 1, duration: 1.28, ease: 'transform' }, 'start')

        .to(from, { scale: 1.1, duration: 1.30, ease: 'transform', transformOrigin: `${window.innerWidth / 2}px ${height}px`,
          onComplete: () => { done(); } }, 'start')
        .to(nav, { autoAlpha: 0, duration: 0.5, ease: 'transform', }, 'start')

        .play();
    }
  }

  in({ to, from, done, trigger }) {
    const tl = gsap.timeline({ paused: true });

    from.remove();

    if (constants.isDevice) {
      document.querySelector('.js-app').scrollTo(0, 0);
    }

    nav.classList.remove('is-light');
    nav.classList.remove('is-dark');
    document.body.classList.remove('is-light');
    document.body.classList.remove('is-dark');

    if (instances.menu.state.open) {
      tl
        .to(instances.menu.state.wrap, { yPercent: 75, duration: 1.28, autoAlpha: 0, ease: 'short-in-out-long', clearProps: 'all' }, 'start')
        .to(instances.menu.state.el, { yPercent: -100, duration: 1.28, ease: 'short-in-out-long', clearProps: 'all' }, 'start');
    } else if (trigger !== 'popstate' && trigger.dataset.background) {
      tl
        .set(transitionEl, { autoAlpha: 0, clearProps: 'all' }, 'start+=0.1');
    } else {
      tl
        .to(transitionText, { yPercent: 75, scale: 1.2, duration: 1.28, ease: 'transform', clearProps: 'all' }, 'start')
        .to(transitionEl, { yPercent: -100, duration: 1.28, ease: 'transform', clearProps: 'all' }, 'start');
    }

    tl.play();

    constants.headerLayout = to.querySelector('.js-header').dataset.layout;
    constants.transitionDuration = tl.duration();

    setTimeout(() => {
      pageEntrance();
    }, tl.duration() * 500);

    done();

    instances.scroll.destroy();
    instances.scroll = undefined;

    instances.menu.destroy();
    instances.menu = undefined;
  }
}

export default DefaultTransition;
