import { gsap } from 'gsap';

import { instances, constants } from '../../store';

import { H } from '../../utils/routing';

class Cases {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-cases');
    this.dom.container = this.dom.el.querySelector('.js-container');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
    this.dom.buttons = this.dom.el.querySelectorAll('.js-filter-button');
    this.dom.hoverItems = this.dom.el.querySelectorAll('.js-hover');

    this.state = {
      animating: false
    };
  }

  objectToQueryString = (obj) => {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
  }

  filter = (e) => {
    if (e.target.classList.contains('is-active') || this.state.animating) return;

    this.state.animating = true;

    [...this.dom.buttons].forEach((button) => button.classList.remove('is-active'));
    e.target.classList.add('is-active');

    const { filter } = e.target.dataset;

    this.data = {
      filter: filter
    };

    if (this.dom.hoverItems.length > 0) {
      [...this.hoverItems].forEach((item) => item.el.removeEventListener('mouseenter', this.toggleHover));
      [...this.hoverItems].forEach((item) => item.el.removeEventListener('mouseleave', this.toggleHover));
    }

    this.request = new XMLHttpRequest();
    this.request.open('POST', `${window.location.origin}/wp-admin/admin-ajax.php?action=filter_cases`, true);

    this.request.onload = () => {
      if (this.request.readyState !== 4) return;

      if (this.request.status >= 200 && this.request.status < 300) {
        gsap.to(this.dom.items, {
          autoAlpha: 0,
          duration: 0.2,
          onComplete: () => {
            this.dom.container.innerHTML = this.request.response;
            instances.scroll.handleResize();

            this.dom.items = this.dom.el.querySelectorAll('.js-item');
            this.dom.hoverItems = this.dom.el.querySelectorAll('.js-hover');

            this.setCache();

            gsap.set(this.dom.items, { autoAlpha: 0, y: 60 });

            gsap.to(this.dom.items, { autoAlpha: 1, y: 0, stagger: 0.05, onComplete: () => {
              this.state.animating = false;
              H.attach(this.dom.items);
            }
            });
          }
        });
      }
    };

    this.request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    this.request.send(this.objectToQueryString(this.data));
  }

  setCache() {
    this.hoverItems = [];

    [...this.dom.hoverItems].forEach((item) => {
      this.hoverItems.push({
        el: item,
        active: false,
        wrap: item.querySelector('.js-img-wrap'),
        video: item.querySelector('.js-cases-video')
      });
    });

    [...this.hoverItems].forEach((item) => {
      item.el.addEventListener('mouseenter', () => {
        this.toggleHover(item);
      });

      item.el.addEventListener('mouseleave', () => {
        this.toggleHover(item);
      });
    });
  }

  play(poster, item) {
    item.active = true;

    item.video.play();
    item.video.currentTime = 0;
    gsap.to(poster, { duration: 0.3, autoAlpha: 0 });
  }

  pause(poster, item) {
    item.active = false;

    item.video.pause();
    gsap.to(poster, { duration: 0.3, autoAlpha: 1 });
  }

  toggleHover = (item) => {
    let poster = item.el.querySelector('.js-img');

    if (!item.active) this.play(poster, item);
    else this.pause(poster, item);
  }

  addlisteners() {
    [...this.dom.buttons].forEach((button) => button.addEventListener('click', this.filter));
  }

  removelisteners() {
    [...this.dom.buttons].forEach((button) => button.removeEventListener('click', this.filter));

    if (this.dom.hoverItems.length > 0) {
      [...this.hoverItems].forEach((item) => item.el.removeEventListener('mouseenter', this.toggleHover));
      [...this.hoverItems].forEach((item) => item.el.removeEventListener('mouseleave', this.toggleHover));
    }
  }

  render = () => {
    if (!instances.scroll || constants.isDevice) return;

    for (let i = 0; i < this.dom.items.length; i += 1) {
      const el = this.dom.items[i];
      const number = i + 1;

      if (number % 2 === 0) {
        el.style.transform = `translateY(${instances.scroll.state.last / 12}px)`;
      } else {
        el.style.transform = `translateY(${Math.abs(instances.scroll.state.last) / 24}px)`;
      }
    }
  }

  init() {
    this.addlisteners();

    if (this.dom.hoverItems.length > 0) {
      this.setCache();
    }

    instances.time.emitter.on('tick', this.render);
  }

  destroy() {
    this.removelisteners();

    if (this.dom.hoverItems.length > 0) {
      this.setCache();
    }

    instances.time.emitter.off('tick', this.render);
  }
}

export default Cases;
