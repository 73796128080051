import { gsap } from 'gsap';

import { constants, instances } from '../../store/index';

import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);

CustomEase.create('short-in-out-long', 'M0,0 C0.43,0 0.2,1 1,1');

class Nav {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');
    this.dom.header = document.querySelector('.js-header');
    this.dom.button = this.dom.el.querySelector('.js-button');
    this.dom.dots = this.dom.button.querySelectorAll('.js-dot');

    this.state = {
      rendering: false,
      hidden: false,
      top: true,
      windowHeight: undefined,
      navHeight: undefined,
      headerHeight: undefined,
      animating: false
    };
  }

  setCache = () => {
    this.state.windowHeight = window.innerHeight;
    this.state.navHeight = this.dom.el.getBoundingClientRect().height + this.dom.el.offsetTop + 1;

    // Header height
    if (constants.headerLayout) {
      this.state.headerHeight = this.dom.header.getBoundingClientRect().height;
    }
  }

  render = () => {
    if (constants.isDevice) {
      if (instances.scroll.state.direction === 'down' && !this.state.hidden) {
        this.state.hidden = true;
        instances.time.emitter.off('tick', this.render);

        this.dom.el.style.transform = `translateY(-${this.state.navHeight}px)`;
      } else if (instances.scroll.state.direction === 'up' && this.state.hidden) {
        this.state.hidden = false;
        this.dom.el.style.transform = 'translateY(-0px)';
      }
    } else {
      if (Math.abs(instances.scroll.state.last) >= this.state.headerHeight && this.state.top) {
        this.state.top = false;
        this.state.hidden = true;
        this.state.rendering = false;

        instances.time.emitter.off('tick', this.render);

        this.dom.el.removeAttribute('style');

        gsap.set(this.dom.el, { y: -this.state.navHeight });
      } else if (!this.state.top && Math.abs(Math.round(instances.scroll.state.last)) < 1) {
        this.state.top = true;
      }

      // Toggle Nav
      if (this.state.top && !constants.isDevice) {
        this.dom.el.style.transform = `translate3d(0px, ${instances.scroll.state.last}px, 0px)`;
      } else if (!this.state.top) {
        if (this.state.hidden && instances.scroll.state.direction === 'up') {
          this.state.hidden = false;

          gsap.to(this.dom.el, { y: 0, duration: 0.2 });
        } else if (!this.state.hidden && instances.scroll.state.direction === 'down') {
          this.state.hidden = true;
          this.state.rendering = false;

          instances.time.emitter.off('tick', this.render);

          gsap.to(this.dom.el, { y: -this.state.navHeight, duration: 0.2 });
        }
      }
    }
  }

  animateButton = () => {
    if (!this.state.animating) {
      this.state.animating = true;
      gsap.to(this.dom.dots, { y: -5, duration: 0.4, stagger: 0.05, ease: 'short-in-out-long' });
      gsap.to(this.dom.dots, { y: 0, duration: 0.4, stagger: 0.05, ease: 'short-in-out-long', delay: 0.2, onComplete: () => { this.state.animating = false; } });
    }
  }

  addListeners() {
    window.addEventListener('resize', this.setCache);

    window.addEventListener('wheel', () => {
      if (instances.scroll.state.direction === 'up' && !this.state.rendering) {
        this.state.rendering = true;

        instances.time.emitter.on('tick', this.render);
      }
    });

    this.dom.button.addEventListener('mouseenter', this.animateButton);
    this.dom.button.addEventListener('touchstart', this.animateButton);
  }

  destroyListeners() {
    window.removeEventListener('resize', this.setCache);
  }

  init() {
    const interval = setInterval(() => {
      if (instances.scroll) {
        clearInterval(interval);

        this.setCache();
        this.addListeners();

        this.state.rendering = true;
        instances.time.emitter.on('tick', this.render);
      }
    });
  }

  destroy() {
    this.destroyListeners();
    instances.time.emitter.off('tick', this.render);
  }
}

export default Nav;
