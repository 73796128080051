import { constants, instances } from '../../store';

class CaseLongImage {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
  }

  init() {
    if (constants.isDevice) return;

    const interval = setInterval(() => {
      if (!document.body.classList.contains('is-transitioning')) {
        clearInterval(interval);

        instances.scroll.handleResize();
      }
    });
  }
}

export default CaseLongImage;
