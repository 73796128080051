import { constants, instances } from '../../store';
import { lerp } from '../../utils/math';

class ImageGrid {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.con = this.dom.el.querySelector('.js-container');
    this.dom.content = this.dom.el.querySelector('.js-content');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');

    this.state = {
      isDragging: false,
      containerWidth: 0,
      slidesTotal: this.dom.items.length,
      onX: 0,
      offX: 0,
      currentX: 0,
      lastX: 0,
      min: 0,
      max: 0,
      ease: 0.1,
      speed: 3,
      active: false
    };
  }

  setCache() {
    this.state.active = true;

    this.elTop = this.dom.el.getBoundingClientRect().top;
    this.elBotom = this.dom.el.getBoundingClientRect().bottom;

    const itemWidth = this.dom.items[0].offsetWidth;
    const padding = this.dom.content.currentStyle || window.getComputedStyle(this.dom.content);

    this.state.containerWidth = (this.state.slidesTotal * itemWidth) + ((this.state.slidesTotal - 1) * 10);
    this.state.max = -(this.state.containerWidth - window.innerWidth + (parseInt(padding.paddingLeft, 10) * 2));
  }

  handleMouseDown = (e) => {
    e.preventDefault();

    this.state.isDragging = true;
    this.state.onX = e.touches[0].clientX;

    setTimeout(() => {
      this.disableClick = true;
    }, 100);
  }

  handleMouseUp = () => {
    this.state.isDragging = false;
    this.state.offX = this.state.currentX;

    setTimeout(() => {
      this.disableClick = false;
    }, 100);
  }

  handleMouseMove = (e) => {
    this.state.xPos = e.pageX;
    this.state.yPos = e.pageY;

    if (!this.state.isDragging) return;

    const clientX = e.touches[0].clientX;

    this.state.currentX = this.state.offX + ((clientX - this.state.onX) * this.state.speed);
    this.clamp();
  }

  clamp() {
    this.state.currentX = Math.max(Math.min(this.state.currentX, this.state.min), this.state.max);
  }

  render = () => {
    if (!this.state.active) this.setCache();

    this.state.lastX = lerp(this.state.lastX, this.state.currentX, this.state.ease);
    this.state.lastX = Math.floor(this.state.lastX * 100) / 100;

    this.dom.con.style.transform = `translate3d(${this.state.lastX}px, 0, 0)`;
  }

  addListeners() {
    this.dom.con.addEventListener('touchstart', this.handleMouseDown);
    this.dom.con.addEventListener('touchend', this.handleMouseUp);
    this.dom.con.addEventListener('touchmove', this.handleMouseMove);
  }

  removeListeners() {
    this.dom.con.removeEventListener('touchstart', this.handleMouseDown);
    this.dom.con.removeEventListener('touchend', this.handleMouseUp);
    this.dom.con.removeEventListener('touchmove', this.handleMouseMove);
  }

  init() {
    if (constants.isDevice) {
      instances.time.emitter.on('tick', this.render);
      this.addListeners();
    }
  }

  destroy() {
    if (constants.isDevice) {
      instances.time.emitter.off('tick', this.render);
      this.removeListeners();
    }
  }
}

export default ImageGrid;
