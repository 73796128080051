import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants, instances } from '../../store';

gsap.registerPlugin(CustomEase);

CustomEase.create('short-in-out-long', 'M0,0 C0.43,0 0.2,1 1,1');
CustomEase.create('text-easing', 'M0,0 C0.44652,0 0.0509,1 1,1');

class Menu {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-menu');
    this.dom.wrap = this.dom.el.querySelector('.js-wrap');
    this.dom.top = this.dom.el.querySelector('.js-top');
    this.dom.primLinks = this.dom.el.querySelectorAll('.js-primary-link');
    this.dom.seconLinks = this.dom.el.querySelectorAll('.js-secondary-link');
    this.dom.tertLinks = this.dom.el.querySelectorAll('.js-tertiary-link');
    this.dom.close = this.dom.el.querySelector('.js-close');
    this.dom.langSwitch = this.dom.el.querySelector('.js-lang-switch');

    this.dom.nav = document.querySelector('.js-nav');
    this.dom.open = this.dom.nav.querySelector('.js-open');

    this.dom.page = document.querySelector('.js-page');

    this.state = {
      el: this.dom.el,
      wrap: this.dom.wrap,
      open: false,
      done: true,
      animationCompleted: false
    };

    this.height = 0;
  }

  createAnimation() {
    this.tl = gsap.timeline({ paused: true, onComplete: () => this.state.animationCompleted = true });

    this.tl
      .addLabel('start')
      .set(this.dom.el, { autoAlpha: 1, yPercent: 100, borderRadius: '100%' })
      .set(this.dom.wrap, { yPercent: -100, autoAlpha: 1 })
      .set(this.dom.top, { autoAlpha: 0, y: 130 })
      .set(this.dom.primLinks, { autoAlpha: 0, scale: 0.68, y: 118 })
      .set(this.dom.seconLinks, { autoAlpha: 0, y: 300 })
      .set(this.dom.tertLinks, { autoAlpha: 0, y: 81, scale: 0.7 })
      .set(this.dom.langSwitch, { autoAlpha: 0, y: 81, scale: 0.7 })

      .to(this.dom.el, { borderRadius: 0, duration: 0.9, ease: 'short-in-out-long' }, 'start')
      .to(this.dom.el, { yPercent: 0, duration: 1.28, ease: 'short-in-out-long' }, 'start')
      .to(this.dom.wrap, { yPercent: 0, duration: 1.28, ease: 'short-in-out-long' }, 'start')
      .to(this.dom.top, { y: 0, autoAlpha: 1, duration: 1.47, ease: 'short-in-out-long' }, 'start+=0.31')
      .to(this.dom.nav, { autoAlpha: 0, duration: 1.28, ease: 'short-in-out-long', clearProps: 'all' }, 'start')
      .to(this.dom.page, { scale: 1.1, duration: 1.28, ease: 'short-in-out-long', transformOrigin: `${window.innerWidth / 2}px ${this.height}px` }, 'start')
      .to(this.dom.primLinks, { y: 0, autoAlpha: 1, scale: 1, duration: 1, ease: 'text-easing', stagger: 0.07 }, 'start+=0.48')
      .to(this.dom.seconLinks, { y: 0, autoAlpha: 1, duration: 1.27, ease: 'text-easing', stagger: 0.03 }, 'start+=0.45')
      .to(this.dom.tertLinks, { y: 0, autoAlpha: 1, scale: 1, duration: 1.47, ease: 'short-in-out-long', stagger: 0.03 }, 'start+=0.37')
      .to(this.dom.langSwitch, { y: 0, autoAlpha: 1, scale: 1, duration: 1.47, ease: 'short-in-out-long' }, 'start+=0.98');
  }

  openMenu = () => {
    if (!this.state.done) {
      if (!constants.isDevice) instances.scroll.enable();

      this.state.done = true;
      this.state.open = false;

      this.tl.reverse();
    } else {
      if (!constants.isDevice) instances.scroll.disable();

      this.state.done = false;
      this.state.open = true;

      if (this.state.animationCompleted) {
        this.state.animationCompleted = false;
        this.tl.play(0);
      } else {
        const { top } = this.dom.page.getBoundingClientRect();
        this.height = (top >= 0) ? window.innerHeight / 2 : Math.abs(top);

        this.tl.play();
      }
    }
  }

  closeMenu = () => {
    if (!constants.isDevice) instances.scroll.enable();

    this.state.done = true;
    this.state.open = false;

    this.tlClose = gsap.timeline({ paused: true });

    this.tlClose
      .addLabel('start')
      .to(this.dom.page, { scale: 1, duration: 1.28, ease: 'short-in-out-long', clearProps: 'scale' }, 'start')
      .to(this.dom.nav, { autoAlpha: 1, duration: 1.28, ease: 'short-in-out-long' }, 'start')
      .to(this.dom.wrap, { yPercent: 75, duration: 1.28, autoAlpha: 0, ease: 'short-in-out-long' }, 'start')
      .to(this.dom.el, { yPercent: -100, duration: 1.28, ease: 'short-in-out-long' }, 'start');

    this.tlClose.play();
  }

  addListeners() {
    this.dom.open.addEventListener('click', this.openMenu);
    this.dom.close.addEventListener('click', this.closeMenu);
  }

  removeListeners() {
    this.dom.open.removeEventListener('click', this.openMenu);
    this.dom.close.removeEventListener('click', this.closeMenu);
  }

  init() {
    this.addListeners();
    this.createAnimation();
  }

  destroy() {
    this.removeListeners();
  }
}

export default Menu;
