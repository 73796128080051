export const constants = {
  isDevice: undefined,
  theme: 'is-light',
  transitionDuration: undefined,
  showPreloader: false,
  pageEntranceFinished: false
};

export const instances = {
  scroll: undefined,
};
