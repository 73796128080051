import { gsap } from 'gsap';

class newsGrid {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-news-grid');
    this.dom.hoverItems = this.dom.el.querySelectorAll('.js-hover');
  }

  setCache() {
    this.hoverItems = [];

    [...this.dom.hoverItems].forEach((item) => {
      this.hoverItems.push({
        el: item,
        active: false,
        wrap: item.querySelector('.js-img-wrap'),
        video: item.querySelector('.js-news-grid-video')
      });
    });

    [...this.hoverItems].forEach((item) => {
      item.el.addEventListener('mouseenter', () => {
        this.toggleHover(item);
      });

      item.el.addEventListener('mouseleave', () => {
        this.toggleHover(item);
      });
    });
  }

  play(poster, item) {
    item.active = true;

    item.video.play();
    item.video.currentTime = 0;
    gsap.to(poster, { duration: 0.3, autoAlpha: 0 });
  }

  pause(poster, item) {
    item.active = false;

    item.video.pause();
    gsap.to(poster, { duration: 0.3, autoAlpha: 1 });
  }

  toggleHover = (item) => {
    let poster = item.el.querySelector('.js-img');

    if (!item.active) this.play(poster, item);
    else this.pause(poster, item);
  }

  init() {
    if (this.dom.hoverItems.length > 0) {
      this.setCache();
    }
  }

  destroy() {
    if (this.dom.hoverItems.length > 0) {
      this.setCache();
    }
  }
}

export default newsGrid;
