export const inViewport = (val, el) => {
  const top = el.offsetTop;
  const bottom = el.offsetTop + el.offsetHeight;

  return val + window.innerHeight >= top && val < bottom;
};

export const intersectRatio = (val, el) => {
  const min = el.offsetTop - window.innerHeight;
  const max = el.offsetTop + el.offsetHeight;

  return ((val - min) * 100) / (max - min);
};

export const intersectRatioTop = (val, el) => {
  const min = el.offsetTop;
  const max = el.offsetTop + el.offsetHeight;

  return ((val - min) * 100) / (max - min);
};

export const intersectMinMax = (val, el, min, max, top = false) => {
  let percentage;

  if (top) {
    percentage = intersectRatioTop(val, el);
  } else {
    percentage = intersectRatio(val, el);
  }

  return ((percentage - 0) / (100 - 0)) * (max - min) + min;
};

export const intersectTopMinMax = (val, el, min, max) => {
  const percentage = intersectRatio(val, el);

  return ((percentage - 0) / (100 - 0)) * (max - min) + min;
};
