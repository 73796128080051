import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

CustomEase.create('smooth-in-out', 'M0,0 C0.8,0 0.2,1 1,1 ');

import { inViewport } from '../../utils/in-viewport';
import { constants, instances } from '../../store';

class CaseList {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-case-list');
    this.dom.title = document.querySelector('.js-title');
    this.dom.client = document.querySelector('.js-client');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
    this.dom.itemTitles = this.dom.el.querySelectorAll('.js-item-title');
    this.dom.itemSubtitles = this.dom.el.querySelectorAll('.js-item-subtitle');
    this.dom.imgWrap = this.dom.el.querySelector('.js-img-wrap');
  }

  setCache() {
    this.items = [];

    [...this.dom.items].forEach((el) => {
      this.items.push({
        el,
        title: el.querySelector('.js-item-title'),
        subtitle: el.querySelector('.js-item-subtitle'),
        w480: el.dataset.w480,
        w768: el.dataset.w768,
        w1200: el.dataset.w1200,
        w1440: el.dataset.w1440,
        w1920: el.dataset.w1920,
        w2500: el.dataset.w2500,
        client: el.dataset.client,
        active: false
      });
    });

    const firstImg = document.createElement('picture');

    const sourceW480 = document.createElement('source');
    sourceW480.media = '(max-width: 480px)';
    sourceW480.srcset = this.items[0].w480;
    firstImg.appendChild(sourceW480);

    const sourceW768 = document.createElement('source');
    sourceW768.media = '(min-width: 480px) and (max-width: 768px)';
    sourceW768.srcset = this.items[0].w768;
    firstImg.appendChild(sourceW768);

    const sourceW1200 = document.createElement('source');
    sourceW1200.media = '(min-width: 768px) and (max-width: 1180px)';
    sourceW1200.srcset = this.items[0].w1200;
    firstImg.appendChild(sourceW1200);

    const sourceW1440 = document.createElement('source');
    sourceW1440.media = '(min-width: 1180px) and (max-width: 1440px)';
    sourceW1440.srcset = this.items[0].w1440;
    firstImg.appendChild(sourceW1440);

    const sourceW1920 = document.createElement('source');
    sourceW1920.media = '(min-width: 1440px) and (max-width: 1920px)';
    sourceW1920.srcset = this.items[0].w1920;
    firstImg.appendChild(sourceW1920);

    const img = document.createElement('img');
    img.src = this.items[0].w2500;
    firstImg.appendChild(img);

    this.dom.imgWrap.appendChild(firstImg);

    this.items[0].active = true;
  }

  backgroundPos = () => {
    const padding = this.dom.el.offsetTop + (window.innerWidth / 1440) * 150;

    this.dom.imgWrap.style.top = `-${padding}px`;
  }

  render = () => {
    if (!instances.scroll) return;

    const isVisible = inViewport(Math.abs(instances.scroll.state.last), this.dom.el);

    if (isVisible) {
      this.dom.imgWrap.style.transform = `translate(0, ${-instances.scroll.state.last}px)`;

      const newPos = instances.scroll.state.last;
      const diff = newPos - instances.scroll.state.current;

      this.transform = diff * 0.2;
      if (diff < 0) this.transform = diff * 0.2;

      [...this.items].forEach((item, index) => {
        gsap.to(item.el, { duration: 0.4, y: this.transform * index });
      });
    }
  }

  handleMouseenter = (index) => {
    const item = this.items[index];

    if (!item.active) {
      this.items.find(el => el.active = false);

      item.active = true;

      item.img = document.createElement('picture');

      const sourceW480 = document.createElement('source');
      sourceW480.media = '(max-width: 480px)';
      sourceW480.srcset = item.w480;
      item.img.appendChild(sourceW480);

      const sourceW768 = document.createElement('source');
      sourceW768.media = '(min-width: 480px) and (max-width: 768px)';
      sourceW768.srcset = item.w768;
      item.img.appendChild(sourceW768);

      const sourceW1200 = document.createElement('source');
      sourceW1200.media = '(min-width: 768px) and (max-width: 1180px)';
      sourceW1200.srcset = item.w1200;
      item.img.appendChild(sourceW1200);

      const sourceW1440 = document.createElement('source');
      sourceW1440.media = '(min-width: 1180px) and (max-width: 1440px)';
      sourceW1440.srcset = item.w1440;
      item.img.appendChild(sourceW1440);

      const sourceW1920 = document.createElement('source');
      sourceW1920.media = '(min-width: 1440px) and (max-width: 1920px)';
      sourceW1920.srcset = item.w1920;
      item.img.appendChild(sourceW1920);

      const img = document.createElement('img');
      img.src = item.w2500;
      item.img.appendChild(img);

      this.dom.imgWrap.appendChild(item.img);

      img.style.visibility = 'hidden';
      img.style.opacity = 0;
      img.style.transform = 'scale(1.2)';

      this.dom.imgWrap.appendChild(item.img);

      gsap.to(img, { duration: 0.4, ease: 'Power3.easeOut', autoAlpha: 1, scale: 1, });
    }

    gsap.fromTo(item.title, {
      autoAlpha: 1,
      y: 0,
    }, {
      duration: 0.9,
      ease: 'Power3.easeOut',
      y: -window.innerWidth * 0.0135,
    });

    gsap.fromTo(item.subtitle, {
      autoAlpha: 0.6,
      y: window.innerWidth * 0.04861111111,
      rotation: 10,
    }, {
      duration: 0.9,
      ease: 'Power3.easeOut',
      y: 0,
      rotation: 0,
    });
  }

  handleMouseleave = (index) => {
    const item = this.items[index];

    if (!item.active) {
      gsap.to(item.img, {
        duration: 0.1,
        autoAlpha: 0,
        onComplete: () => {
          if (!item.img) return;

          this.dom.imgWrap.removeChild(item.img);
          item.img = undefined;
        }
      });
    }

    gsap.to(item.title, {
      duration: 0.9,
      ease: 'Power3.easeOut',
      y: 0,
    });

    gsap.to(item.subtitle, {
      duration: 0.9,
      ease: 'Power3.easeOut',
      y: window.innerWidth * 0.04861111111,
      rotation: 10,
    });
  }

  addListeners() {
    this.dom.el.addEventListener('mouseleave', this.test);

    [...this.dom.items].forEach((item, index) => item.addEventListener('mouseenter', () => this.handleMouseenter(index)));
    [...this.dom.items].forEach((item, index) => item.addEventListener('mouseleave', () => this.handleMouseleave(index)));

    window.addEventListener('resize', this.backgroundPos);
  }

  removeListeners() {
    [...this.dom.items].forEach((item, index) => item.removeEventListener('mouseenter', () => this.handleMouseenter(index)));
    [...this.dom.items].forEach((item, index) => item.removeEventListener('mouseleave', () => this.handleMouseleave(index)));

    window.removeEventListener('resize', this.backgroundPos);
  }

  init() {
    if (constants.isDevice) return;

    this.setCache();
    this.backgroundPos();
    this.addListeners();

    instances.time.emitter.on('tick', this.render);
  }

  destroy() {
    this.removeListeners();
    instances.time.emitter.off('tick', this.render);
  }
}

export default CaseList;
