import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

// import { constants } from '../../store';

class LinkAnims {
  constructor() {
    this.dom = {};
    this.dom.links = document.querySelectorAll('.js-hover-link');
  }

  createChars() {
    this.links = [];

    [...this.dom.links].forEach((el) => {
      this.links.push({
        primary: el.querySelector('.js-link-title'),
        secondary: el.querySelector('.js-link-title-secondary'),
        primaryText: el.querySelector('.js-link-title').textContent.trim(),
        secondaryText: el.querySelector('.js-link-title-secondary').textContent.trim(),
      });
    });

    [...this.links].forEach((link) => {
      const primary = new SplitText(link.primary, { type: 'chars', charsClass: 'link__title-char' });
      const secondary = new SplitText(link.secondary, { type: 'chars', charsClass: 'link__title-char' });

      return [primary, secondary];
    });
  }

  removeChars() {
    [...this.links].forEach((link) => {
      link.primary.innerHTML = link.primaryText;
      link.secondary.innerHTML = link.secondaryText;
    });
  }

  animIcon = (icon) => {
    if (!icon) return;

    gsap.to(icon, { scale: 0.8, duration: 0.2 });
    gsap.to(icon, { scale: 1, duration: 0.2, delay: 0.2 });
  }

  addListener() {
    this.dom.links.forEach(link => {
      if (link.querySelector('.js-link-icon')) {
        const icon = link.querySelector('.js-link-icon');

        link.addEventListener('click', () => this.animIcon(icon));
      }
    });
  }

  removeListener() {
    this.dom.links.forEach(link => {
      if (link.querySelector('.js-link-icon')) {
        link.removeEventListener('click', this.animIcon());
      }
    });
  }

  init() {
    this.createChars();
    this.addListener();
  }

  reset() {
    this.removeChars();
    this.removeListener();
  }
}

export default LinkAnims;
