import { gsap } from 'gsap';
import { inViewport } from '../../utils/in-viewport';
import { instances } from '../../store';

class ImageScale {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.imgWrap = this.dom.el.querySelector('.js-img-wrap');
    this.dom.img = this.dom.imgWrap.querySelector('.js-img');

    this.state = {
      start: false
    };
  }

  render = () => {
    if (!instances.scroll) return;

    const inView = inViewport(Math.abs(instances.scroll.state.last), this.dom.el);

    if (inView) {
      const { top } = this.dom.el.getBoundingClientRect();

      if (Math.abs(top - window.innerHeight) > this.dom.el.offsetHeight) return;

      let percentage = (Math.abs(top - window.innerHeight) / this.dom.el.offsetHeight) * 100;

      if (percentage >= 100) return;

      const scaleUp = ((percentage - 0) / (100 - 0)) * (1 - 0.7) + 0.7;
      const scaleDown = ((percentage - 0) / (100 - 0)) * (1 - 1.5) + 1.5;

      gsap.set(this.dom.imgWrap, { scale: scaleUp });
      gsap.set(this.dom.img, { scale: scaleDown });
    }
  }

  init() {
    instances.time.emitter.on('tick', this.render);
  }
}

export default ImageScale;
