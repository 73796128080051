import Scroll from '../utils/scroll';
import Time from '../utils/time';
import { pageEntrance } from '../utils/page-entrance-anims';
import { constants, instances } from '../store';

import LinkAnims from '../components/link/link';
import Nav from '../components/nav/nav';
import Menu from '../components/menu/menu';
import Cursor from '../components/cursor/cursor';
import Preloader from '../components/preloader/preloader';
import CookieManager from '../components/cookie/cookie';

const globalEvents = (namespace = null) => {
  constants.namespace = namespace;

  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else if (window.innerWidth >= 768 && window.innerWidth < 1180) constants.size = 'desktop-sm';
  else if (window.innerWidth >= 1180 && window.innerWidth < 1440) constants.size = 'desktop-md';
  else if (window.innerWidth >= 1440 && window.innerWidth < 1920) constants.size = 'desktop-lg';
  else constants.size = 'desktop-xl';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // eslint-disable-next-line no-new
  new CookieManager();

  instances.links = new LinkAnims();
  instances.links.init();

  instances.nav = new Nav();
  instances.nav.init();

  instances.menu = new Menu();
  instances.menu.init();

  instances.scroll = new Scroll();
  instances.scroll.init();

  instances.cursor = new Cursor();
  instances.cursor.init();
};

const onResize = () => {
  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else constants.size = 'desktop';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const onReady = () => {
  const view = document.querySelector('[data-router-view]');
  const namespace = view.dataset.routerView;

  // detect mobile device and set body class
  constants.isDevice = 'ontouchstart' in window;
  constants.isDevice && document.body.classList.add('is-device');

  constants.reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  constants.dpr = window.devicePixelRatio;

  const header = document.querySelector('.js-header');
  if (header) constants.headerLayout = header && header.dataset.layout;

  instances.time = new Time();

  if (constants.showPreloader) {
    instances.preloader = new Preloader();
    instances.preloader.init();
  } else {
    document.querySelector('.js-preloader').remove();
    pageEntrance(document.body);
  }

  globalEvents(namespace);

  window.addEventListener('resize', onResize);
};

export const onLeave = () => {
  document.body.classList.add('is-transitioning');
  constants.pageEntranceFinished = false;

  if (instances.nav) {
    instances.nav.destroy();
    instances.nav = undefined;
  }

  if (instances.cursor) {
    instances.cursor.reset();
  }

  if (instances.links) {
    instances.links.reset();
    instances.links = undefined;
  }
};

export const onEnter = () => {};

export const onEnterCompleted = ({ to }) => {
  globalEvents(to.view.dataset.routerView);
};
