import { constants, instances } from '../../store';

class Grid {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-grid');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
  }

  render = () => {
    /* Left */
    this.dom.items[0].style.transform = `translateY(${(Math.abs(instances.scroll.state.last) - 1800) / 6}px)`;

    /* Right */
    this.dom.items[1].style.transform = `translateY(${instances.scroll.state.last / 12}px)`;
  }

  init() {
    if (constants.isDevice) return;

    instances.time.emitter.on('tick', this.render);
  }

  destroy() {
    if (constants.isDevice) return;

    instances.time.emitter.off('tick', this.render);
  }
}

export default Grid;
