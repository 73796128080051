import { onReady } from './renderers/global';

// import styles
import './index.scss';

// import routing
import './utils/routing';

// the location on the page is not restored, user will need to scroll manually
if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual';

// fire onReady fn on DOMContentLoaded
onReady();
